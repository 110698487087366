/* Primary styles for In The Same Boats
 * SASS file outputs to assets/css/style.css  */
body {
  background: #FFFFFF;
  width: 100%;
  height: 100%;
  font-family: "Proxima Nova", "proxima-nova-soft", "Montserrat", "Arial", sans-serif;
  color: #000000;
  margin: 0;
  padding: 0;
  min-width: 1250px;
  position: inherit;
}

body.visualization {
  overflow: hidden;
}

body > div {
  position: relative;
  width: 100%;
  height: 100%;
}

div, a {
  box-sizing: border-box;
}

a, a:link, a:visited {
  color: #000000;
  text-decoration: none;
}

a:hover, a:link:hover, a:visited:hover {
  text-decoration: underline;
}

figure {
  margin: 0;
}

body.front #nav {
  width: 300px;
}

#nav {
  position: fixed;
  width: 75px;
  background-color: #000000;
  color: #FFFFFF;
  top: 0;
  left: 0;
  z-index: 10;
  border-right: solid 1px #FFFFFF;
  overflow: hidden;
  transition: width 0.5s;
  transition-delay: 0.5s;
}

#nav:hover {
  width: 300px;
}

#title {
  display: flex;
  width: 300px;
  height: 70px;
  background-color: #fcb040;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: solid 1px #000000;
}

#title figure {
  width: 75px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#title figure img {
  display: inline-block;
  width: 40px;
}

#title span {
  display: block;
  color: #000000;
  font-size: 1.2em;
  font-weight: 600;
  text-transform: uppercase;
  width: 200px;
}

#tabs {
  width: 300px;
  letter-spacing: 0.1em;
  padding: 25px;
}

#tabs a, #tabs a:link, #tabs a:visited {
  color: #FFFFFF;
}

#tabs a {
  font-size: 1em;
  font-weight: 600;
  text-transform: uppercase;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}

#tabs a:hover, #tabs a.active {
  text-decoration: none;
  color: #fcb040;
}

#tabs img {
  width: 25px;
  margin-right: 25px;
}

#panels .panel {
  height: 100%;
}

#contents {
  position: relative;
  padding-left: 100px;
  width: 100%;
}

.front #contents {
  padding-left: 350px;
}

.visualization #contents {
  margin-left: 75px;
  width: unset;
}

.visualization #contents > #panels {
  overflow: hidden;
}

#container {
  pointer-events: none;
}

#page-title {
  width: 90%;
  margin: 0 auto;
  font-size: 2em;
}

#page-title h1 {
  margin: 1.25em auto 0em;
  font-weight: normal;
}

#page {
  display: flex;
  flex-direction: row;
}

.page-text {
  width: 90%;
  margin: 0 auto;
  font-size: 0.9em;
}

.page-text.has-sidebar {
  width: 55%;
}

.page-text h1, .page-text h2 {
  color: #000000;
}

.page-text a {
  text-decoration: underline;
}

.page-text p {
  font-size: 1.2em;
  line-height: 1.5em;
  color: #000000;
}

.sidebar {
  width: 25%;
  margin: 0 auto;
}

.sidebar a {
  text-transform: uppercase;
  font-weight: bold;
}

.sidebar h3 {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.sidebar_item {
  height: max-content;
  clear: both;
  overflow: hidden;
  margin-bottom: 1.25em;
}

.item_icon {
  float: left;
  width: 20%;
}

.item_icon img {
  width: 40px;
}

.item_info {
  float: right;
  width: 80%;
}

.item_info h3 {
  margin-top: 0;
}

span.subject {
  display: block;
  font-style: italic;
}

.about-text {
  width: 62.5%;
  margin: 0 auto;
  padding-top: 1.25em;
}

.about-text h1, .about-text h2 {
  color: #6A727C;
}

.about-text a {
  text-decoration: underline;
}

.about-text p {
  font-size: 1.2em;
  line-height: 1.5em;
  color: #6A727C;
}

.header {
  width: 100%;
  height: 70px;
  padding: 1em 0;
  line-height: 2em;
  text-transform: uppercase;
  text-align: center;
  background-color: #585f66;
  color: #FFFFFF;
  letter-spacing: 0.1em;
  font-weight: bold;
  border-right: solid 1px #FFFFFF;
  border-bottom: solid 1px #FFFFFF;
  overflow: hidden;
  white-space: nowrap;
}

.panel > *:last-child .header {
  border-right-width: 0;
}

/* !VISUALIZATION PANEL STRUCTURE */
#intersections > * {
  position: absolute;
  top: 0;
  bottom: 0;
}

#intersections > *:nth-child(1) {
  width: 15%;
  left: 0;
}

#intersections > *:nth-child(2) {
  width: 60%;
  left: 15%;
}

#intersections > *:nth-child(3) {
  width: 25%;
  left: 75%;
}

#intersections .location .header {
  background-color: #6A727C;
}

#trajectories > * {
  position: absolute;
  top: 0;
  bottom: 0;
}

#trajectories > *:nth-child(1) {
  width: 15%;
  left: 0;
}

#trajectories > *:nth-child(2) {
  width: 25%;
  left: 15%;
}

#trajectories > *:nth-child(3) {
  width: 60%;
  left: 40%;
}

#itineraries
> * {
  position: absolute;
  top: 0;
  height: auto;
}

#itineraries
> *:nth-child(1) {
  width: 25%;
  min-height: 100%;
  left: 0;
}

#itineraries
> *:nth-child(1) .header {
  background-color: #6A727C;
}

#itineraries
> *:nth-child(2) {
  width: 75%;
  left: 25%;
}

#itineraries
> * .selections {
  width: 100%;
}

#itineraries
> * .itinerary {
  width: 100%;
  height: auto;
}

#itineraries
> * .itinerary .itinerary-header {
  width: 100%;
  text-align: center;
  padding: 1.25em 0 0.625em 0;
  text-transform: uppercase;
  color: #406afc;
  font-weight: 600;
}

#itineraries
> * .itinerary .itinerary-view {
  text-align: center;
  padding-top: 1.25em;
  height: auto;
  width: 100%;
}

#itineraries
> * .itinerary svg {
  width: 60px;
  display: inline-block;
  background-color: #F7F8F9;
  padding: 1.25em 0;
  overflow: visible;
}

/* Routes */
.id_0 {
  fill: #fcb040;
  color: #fcb040;
}

.id_0 path, .id_0 path.id_0, .id_0 line.id_0 {
  fill: none;
  stroke: #fcb040;
}

.id_1 {
  fill: #406afc;
  color: #406afc;
}

.id_1 path, .id_1 path.id_1, .id_1 line.id_1 {
  fill: none;
  stroke: #406afc;
}

.id_2 {
  fill: #730c51;
  color: #730c51;
}

.id_2 path, .id_2 path.id_2, .id_2 line.id_2 {
  fill: none;
  stroke: #730c51;
}

.id_3 {
  fill: #6c3c96;
  color: #6c3c96;
}

.id_3 path, .id_3 path.id_3, .id_3 line.id_3 {
  fill: none;
  stroke: #6c3c96;
}

.id_4 {
  fill: #fcbc51;
  color: #fcbc51;
}

.id_4 path, .id_4 path.id_4, .id_4 line.id_4 {
  fill: none;
  stroke: #fcbc51;
}

.id_5 {
  fill: #e2b423;
  color: #e2b423;
}

.id_5 path, .id_5 path.id_5, .id_5 line.id_5 {
  fill: none;
  stroke: #e2b423;
}

.id_6 {
  fill: #00d8e2;
  color: #00d8e2;
}

.id_6 path, .id_6 path.id_6, .id_6 line.id_6 {
  fill: none;
  stroke: #00d8e2;
}

.id_7 {
  fill: #72c166;
  color: #72c166;
}

.id_7 path, .id_7 path.id_7, .id_7 line.id_7 {
  fill: none;
  stroke: #72c166;
}

.id_8 {
  fill: #fed634;
  color: #fed634;
}

.id_8 path, .id_8 path.id_8, .id_8 line.id_8 {
  fill: none;
  stroke: #fed634;
}

.id_9 {
  fill: #fe7234;
  color: #fe7234;
}

.id_9 path, .id_9 path.id_9, .id_9 line.id_9 {
  fill: none;
  stroke: #fe7234;
}

.id_10 {
  fill: #e39e3a;
  color: #e39e3a;
}

.id_10 path, .id_10 path.id_10, .id_10 line.id_10 {
  fill: none;
  stroke: #e39e3a;
}

.id_11 {
  fill: #3a5fe3;
  color: #3a5fe3;
}

.id_11 path, .id_11 path.id_11, .id_11 line.id_11 {
  fill: none;
  stroke: #3a5fe3;
}

.id_12 {
  fill: #802462;
  color: #802462;
}

.id_12 path, .id_12 path.id_12, .id_12 line.id_12 {
  fill: none;
  stroke: #802462;
}

.id_13 {
  fill: #613687;
  color: #613687;
}

.id_13 path, .id_13 path.id_13, .id_13 line.id_13 {
  fill: none;
  stroke: #613687;
}

.id_14 {
  fill: #e3a949;
  color: #e3a949;
}

.id_14 path, .id_14 path.id_14, .id_14 line.id_14 {
  fill: none;
  stroke: #e3a949;
}

.id_15 {
  fill: #cba220;
  color: #cba220;
}

.id_15 path, .id_15 path.id_15, .id_15 line.id_15 {
  fill: none;
  stroke: #cba220;
}

.id_16 {
  fill: #00c3cb;
  color: #00c3cb;
}

.id_16 path, .id_16 path.id_16, .id_16 line.id_16 {
  fill: none;
  stroke: #00c3cb;
}

.id_17 {
  fill: #67ae5c;
  color: #67ae5c;
}

.id_17 path, .id_17 path.id_17, .id_17 line.id_17 {
  fill: none;
  stroke: #67ae5c;
}

.id_18 {
  fill: #e5c12f;
  color: #e5c12f;
}

.id_18 path, .id_18 path.id_18, .id_18 line.id_18 {
  fill: none;
  stroke: #e5c12f;
}

.id_19 {
  fill: #e4672f;
  color: #e4672f;
}

.id_19 path, .id_19 path.id_19, .id_19 line.id_19 {
  fill: none;
  stroke: #e4672f;
}

.id_20 {
  fill: #ca8d33;
  color: #ca8d33;
}

.id_20 path, .id_20 path.id_20, .id_20 line.id_20 {
  fill: none;
  stroke: #ca8d33;
}

.id_21 {
  fill: #3355ca;
  color: #3355ca;
}

.id_21 path, .id_21 path.id_21, .id_21 line.id_21 {
  fill: none;
  stroke: #3355ca;
}

.id_22 {
  fill: #8d3b73;
  color: #8d3b73;
}

.id_22 path, .id_22 path.id_22, .id_22 line.id_22 {
  fill: none;
  stroke: #8d3b73;
}

.id_23 {
  fill: #563078;
  color: #563078;
}

.id_23 path, .id_23 path.id_23, .id_23 line.id_23 {
  fill: none;
  stroke: #563078;
}

.id_24 {
  fill: #ca9641;
  color: #ca9641;
}

.id_24 path, .id_24 path.id_24, .id_24 line.id_24 {
  fill: none;
  stroke: #ca9641;
}

.id_25 {
  fill: #b5901c;
  color: #b5901c;
}

.id_25 path, .id_25 path.id_25, .id_25 line.id_25 {
  fill: none;
  stroke: #b5901c;
}

.id_26 {
  fill: #00adb5;
  color: #00adb5;
}

.id_26 path, .id_26 path.id_26, .id_26 line.id_26 {
  fill: none;
  stroke: #00adb5;
}

.id_27 {
  fill: #5b9a52;
  color: #5b9a52;
}

.id_27 path, .id_27 path.id_27, .id_27 line.id_27 {
  fill: none;
  stroke: #5b9a52;
}

.id_28 {
  fill: #cbab2a;
  color: #cbab2a;
}

.id_28 path, .id_28 path.id_28, .id_28 line.id_28 {
  fill: none;
  stroke: #cbab2a;
}

.id_29 {
  fill: #cb5b2a;
  color: #cb5b2a;
}

.id_29 path, .id_29 path.id_29, .id_29 line.id_29 {
  fill: none;
  stroke: #cb5b2a;
}

.id_30 {
  fill: #b07b2d;
  color: #b07b2d;
}

.id_30 path, .id_30 path.id_30, .id_30 line.id_30 {
  fill: none;
  stroke: #b07b2d;
}

.id_31 {
  fill: #2d4ab0;
  color: #2d4ab0;
}

.id_31 path, .id_31 path.id_31, .id_31 line.id_31 {
  fill: none;
  stroke: #2d4ab0;
}

.id_32 {
  fill: #9b5383;
  color: #9b5383;
}

.id_32 path, .id_32 path.id_32, .id_32 line.id_32 {
  fill: none;
  stroke: #9b5383;
}

.id_33 {
  fill: #4c2a69;
  color: #4c2a69;
}

.id_33 path, .id_33 path.id_33, .id_33 line.id_33 {
  fill: none;
  stroke: #4c2a69;
}

.id_34 {
  fill: #b08438;
  color: #b08438;
}

.id_34 path, .id_34 path.id_34, .id_34 line.id_34 {
  fill: none;
  stroke: #b08438;
}

.id_35 {
  fill: #9e7e19;
  color: #9e7e19;
}

.id_35 path, .id_35 path.id_35, .id_35 line.id_35 {
  fill: none;
  stroke: #9e7e19;
}

.id_36 {
  fill: #00979e;
  color: #00979e;
}

.id_36 path, .id_36 path.id_36, .id_36 line.id_36 {
  fill: none;
  stroke: #00979e;
}

.id_37 {
  fill: #508747;
  color: #508747;
}

.id_37 path, .id_37 path.id_37, .id_37 line.id_37 {
  fill: none;
  stroke: #508747;
}

.id_38 {
  fill: #b29624;
  color: #b29624;
}

.id_38 path, .id_38 path.id_38, .id_38 line.id_38 {
  fill: none;
  stroke: #b29624;
}

.id_39 {
  fill: #b25024;
  color: #b25024;
}

.id_39 path, .id_39 path.id_39, .id_39 line.id_39 {
  fill: none;
  stroke: #b25024;
}

.id_40 {
  fill: #976a26;
  color: #976a26;
}

.id_40 path, .id_40 path.id_40, .id_40 line.id_40 {
  fill: none;
  stroke: #976a26;
}

.id_41 {
  fill: #263f97;
  color: #263f97;
}

.id_41 path, .id_41 path.id_41, .id_41 line.id_41 {
  fill: none;
  stroke: #263f97;
}

.id_42 {
  fill: #a86a94;
  color: #a86a94;
}

.id_42 path, .id_42 path.id_42, .id_42 line.id_42 {
  fill: none;
  stroke: #a86a94;
}

.id_43 {
  fill: #41245a;
  color: #41245a;
}

.id_43 path, .id_43 path.id_43, .id_43 line.id_43 {
  fill: none;
  stroke: #41245a;
}

.id_44 {
  fill: #977130;
  color: #977130;
}

.id_44 path, .id_44 path.id_44, .id_44 line.id_44 {
  fill: none;
  stroke: #977130;
}

.id_45 {
  fill: #886c15;
  color: #886c15;
}

.id_45 path, .id_45 path.id_45, .id_45 line.id_45 {
  fill: none;
  stroke: #886c15;
}

.id_46 {
  fill: #008288;
  color: #008288;
}

.id_46 path, .id_46 path.id_46, .id_46 line.id_46 {
  fill: none;
  stroke: #008288;
}

.id_47 {
  fill: #44743d;
  color: #44743d;
}

.id_47 path, .id_47 path.id_47, .id_47 line.id_47 {
  fill: none;
  stroke: #44743d;
}

.id_48 {
  fill: #98801f;
  color: #98801f;
}

.id_48 path, .id_48 path.id_48, .id_48 line.id_48 {
  fill: none;
  stroke: #98801f;
}

.id_49 {
  fill: #98441f;
  color: #98441f;
}

.id_49 path, .id_49 path.id_49, .id_49 line.id_49 {
  fill: none;
  stroke: #98441f;
}

.id_50 path, .id_50 path.id_50, .id_50 line.id_50 {
  fill: none;
}

._02 .control {
  z-index: 1;
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
}

#itinerary-axis text {
  fill: #6A727C;
  font-size: 11px;
}

#itinerary-axis line {
  stroke: #6A727C;
}

text.route_label {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-anchor: middle;
}

text.route_point_labels {
  font-size: 11px;
  letter-spacing: 1px;
  fill: #aaa;
}

line.route_line_background {
  stroke: #ddd;
  stroke-width: 30;
}

line.route_line {
  stroke-width: 30;
}

line.route_points {
  stroke: #aaa;
  stroke-width: 1;
  stroke-dasharray: 1, 1;
}

circle.route_stops {
  fill: #fcb040;
  stroke: #fcb040;
  stroke-width: 1;
}

path.line {
  fill: none;
}

.author_left {
  fill: #406afc;
  color: #406afc;
}

.author_left path, path.author_left, line.author_left {
  fill: none;
  stroke: #406afc;
}

.author_right {
  fill: #585f66;
  color: #585f66;
}

.author_right path, path.author_right, line.author_right {
  fill: none;
  stroke: #585f66;
}

.authors {
  background-color: #F0F1F2;
}

.authors .header {
  background-color: #585f66;
}

.authors-list .arow {
  display: table-row;
  width: 100%;
}

.authors-list.small-set a {
  color: #406afc;
  font-size: 1em;
  padding-bottom: 1.25em;
  color: #6A727C;
}

.authors-list.small-set a .item_date {
  text-transform: none;
  font-size: 0.8em;
  color: #c1c5c9;
  font-weight: 300;
}

.authors-list.large-set a {
  color: #406afc;
  font-size: 0.85em;
  padding-bottom: 0.625em;
  color: #6A727C;
}

.authors-list.large-set a .item_date {
  text-transform: none;
  font-size: 0.8em;
  color: #c1c5c9;
  font-weight: 300;
}

.authors-list a {
  width: 50%;
  display: table-cell;
  vertical-align: top;
  padding: 1.25em;
  cursor: pointer;
  padding-bottom: 0.07812em;
}

#trajectories .a-1:hover, #trajectories .a-1.selected {
  color: #fcb040;
}

#trajectories .a-2:hover, #trajectories .a-2.selected {
  color: #406afc;
}

#trajectories .a-3:hover, #trajectories .a-3.selected {
  color: #730c51;
}

#trajectories .a-4:hover, #trajectories .a-4.selected {
  color: #6c3c96;
}

#trajectories .a-5:hover, #trajectories .a-5.selected {
  color: #fcbc51;
}

#trajectories .a-6:hover, #trajectories .a-6.selected {
  color: #e2b423;
}

#trajectories .a-7:hover, #trajectories .a-7.selected {
  color: #00d8e2;
}

#trajectories .a-8:hover, #trajectories .a-8.selected {
  color: #72c166;
}

#trajectories .a-9:hover, #trajectories .a-9.selected {
  color: #fed634;
}

#trajectories .a-10:hover, #trajectories .a-10.selected {
  color: #fe7234;
}

#trajectories .a-11:hover, #trajectories .a-11.selected {
  color: #e39e3a;
}

#trajectories .a-12:hover, #trajectories .a-12.selected {
  color: #3a5fe3;
}

#trajectories .a-13:hover, #trajectories .a-13.selected {
  color: #802462;
}

#trajectories .a-14:hover, #trajectories .a-14.selected {
  color: #613687;
}

#trajectories .a-15:hover, #trajectories .a-15.selected {
  color: #e3a949;
}

#trajectories .a-16:hover, #trajectories .a-16.selected {
  color: #cba220;
}

#trajectories .a-17:hover, #trajectories .a-17.selected {
  color: #00c3cb;
}

#trajectories .a-18:hover, #trajectories .a-18.selected {
  color: #67ae5c;
}

#trajectories .a-19:hover, #trajectories .a-19.selected {
  color: #e5c12f;
}

#trajectories .a-20:hover, #trajectories .a-20.selected {
  color: #e4672f;
}

#trajectories .a-21:hover, #trajectories .a-21.selected {
  color: #ca8d33;
}

#trajectories .a-22:hover, #trajectories .a-22.selected {
  color: #3355ca;
}

#trajectories .a-23:hover, #trajectories .a-23.selected {
  color: #8d3b73;
}

#trajectories .a-24:hover, #trajectories .a-24.selected {
  color: #563078;
}

#trajectories .a-25:hover, #trajectories .a-25.selected {
  color: #ca9641;
}

#trajectories .a-26:hover, #trajectories .a-26.selected {
  color: #b5901c;
}

#trajectories .a-27:hover, #trajectories .a-27.selected {
  color: #00adb5;
}

#trajectories .a-28:hover, #trajectories .a-28.selected {
  color: #5b9a52;
}

#trajectories .a-29:hover, #trajectories .a-29.selected {
  color: #cbab2a;
}

#trajectories .a-30:hover, #trajectories .a-30.selected {
  color: #cb5b2a;
}

#trajectories .a-31:hover, #trajectories .a-31.selected {
  color: #b07b2d;
}

#trajectories .a-32:hover, #trajectories .a-32.selected {
  color: #2d4ab0;
}

#trajectories .a-33:hover, #trajectories .a-33.selected {
  color: #9b5383;
}

#trajectories .a-34:hover, #trajectories .a-34.selected {
  color: #4c2a69;
}

#trajectories .a-35:hover, #trajectories .a-35.selected {
  color: #b08438;
}

#trajectories .a-36:hover, #trajectories .a-36.selected {
  color: #9e7e19;
}

#trajectories .a-37:hover, #trajectories .a-37.selected {
  color: #00979e;
}

#trajectories .a-38:hover, #trajectories .a-38.selected {
  color: #508747;
}

#trajectories .a-39:hover, #trajectories .a-39.selected {
  color: #b29624;
}

#trajectories .a-40:hover, #trajectories .a-40.selected {
  color: #b25024;
}

#trajectories .a-41:hover, #trajectories .a-41.selected {
  color: #976a26;
}

#trajectories .a-42:hover, #trajectories .a-42.selected {
  color: #263f97;
}

#trajectories .a-43:hover, #trajectories .a-43.selected {
  color: #a86a94;
}

#trajectories .a-44:hover, #trajectories .a-44.selected {
  color: #41245a;
}

#trajectories .a-45:hover, #trajectories .a-45.selected {
  color: #977130;
}

#trajectories .a-46:hover, #trajectories .a-46.selected {
  color: #886c15;
}

#trajectories .a-47:hover, #trajectories .a-47.selected {
  color: #008288;
}

#trajectories .a-48:hover, #trajectories .a-48.selected {
  color: #44743d;
}

#trajectories .a-49:hover, #trajectories .a-49.selected {
  color: #98801f;
}

#trajectories .a-50:hover, #trajectories .a-50.selected {
  color: #98441f;
}

#itineraries .authors-list a.selected {
  color: #406afc;
  cursor: pointer;
}

#itineraries svg {
  shape-rendering: geometricPrecision;
  cursor: pointer;
  display: block;
  width: 100%;
}

.results .items {
  padding: 1.25em;
}

.results .title {
  font-size: 1.2em;
  text-transform: uppercase;
  width: 100%;
  padding-bottom: 0.625em;
  margin-bottom: 0.625em;
}

.results .results-view.small-set > div {
  color: #406afc;
  font-size: 1em;
  padding-bottom: 1.25em;
}

.results .results-view.small-set > div .item_date {
  text-transform: none;
  font-size: 0.8em;
  color: #c1c5c9;
  font-weight: 300;
}

.results .results-view.large-set > div {
  color: #406afc;
  font-size: 0.85em;
  padding-bottom: 0.625em;
}

.results .results-view.large-set > div .item_date {
  text-transform: none;
  font-size: 0.8em;
  color: #c1c5c9;
  font-weight: 300;
}

.results .results-view > div {
  width: 50%;
  float: left;
  display: inline-block;
}

.date-range {
  background-color: #F7F8F9;
}

.indicator {
  height: 3em;
  text-align: center;
  padding: 0.9em;
  font-size: 0.8em;
  text-transform: uppercase;
}

.d3-slider {
  font-family: "Proxima Nova", "proxima-nova-soft", "Montserrat", "Arial", sans-serif;
}

.d3-slider-vertical {
  width: 100%;
  height: calc(100% - 3em);
}

.d3-slider-range-vertical {
  background-color: #E6E7E9;
  border-right: 2px solid #406afc;
}

.d3-slider-vertical .d3-slider-handle {
  right: -0.54em;
  left: initial;
  background: #406afc;
  width: 1.2em;
  height: 1.2em;
  margin-bottom: -0.6em;
  border-radius: 0.6em;
  border-color: #406afc;
}

@media only screen and (min-width: 640px) {
  .d3-slider-vertical .d3-slider-handle {
    right: -0.40em;
    width: 1em;
    height: 1em;
    margin-bottom: -0.5em;
    border-radius: 0.5em;
  }
}

.d3-slider-axis line {
  stroke: #FFFFFF;
}

.d3-slider-axis text {
  fill: #000000;
  stroke: transparent;
  font-weight: 400;
  font-size: 0.9em;
  margin-top: 0.2em;
}

.date-selector {
  height: 90%;
  margin: 0;
  padding: 0;
}

.date-selector svg {
  width: 100%;
}

/* Map */
path.map {
  fill: #E6E7E9;
  stroke: transparent;
  stroke-width: 0;
  stroke-opacity: 0;
  stroke-linejoin: miter;
}

.points_g {
  cursor: pointer;
}

.points_g.focus_point .point {
  fill: #406afc;
  stroke: #406afc;
  stroke-width: 1;
  stroke-opacity: 1;
}

.point {
  fill: #fcb040;
  stroke: #fcb040;
  stroke-width: 1;
}

.point_back {
  fill: white;
  fill-opacity: 0.5;
  stroke: transparent;
}

.point.point_01 {
  fill-opacity: 0.25;
  stroke-opacity: 0.25;
}

.point.point_02 {
  fill-opacity: 0.5;
  stroke-opacity: 0.5;
}

.point.point_03 {
  fill-opacity: 1;
  stroke-opacity: 1;
}

path.line {
  stroke-width: 1;
}

.legend {
  width: 250px;
  position: absolute;
  top: 75%;
  right: 15%;
  float: right;
  z-index: 10;
  font-size: 0.9em;
  border: 1px solid #406afc;
  height: auto;
  cursor: pointer;
}

.legend-header {
  background-color: #406afc;
  color: white;
  text-transform: uppercase;
  padding: 0.3125em;
  font-weight: 600;
}

.legend-body {
  padding: 0.3125em;
  background-color: #FFFFFF;
  opacity: 0.95;
}

.legend-item {
  display: table-row;
  height: 40px;
}

.legend-item > * {
  display: table-cell;
  vertical-align: middle;
}

.legend-text {
  width: 190px;
  height: inherit;
  font-size: 0.9em;
}

.legend-illustration {
  width: 60px;
  height: inherit;
}

/* tooltip */
.tip {
  visibility: hidden;
}

.tooltip {
  position: absolute;
  font-family: "Proxima Nova", "proxima-nova-soft", "Montserrat", "Arial", sans-serif;
  font-size: 12px;
  width: 120px;
  border: 1px solid #fcb040;
  background-color: #fcb040;
  opacity: 0;
  padding: 0.625em;
  cursor: pointer;
  animation: tooltip 0.4s ease-in forwards;
  bottom: auto;
  right: auto;
}

#itineraries .tooltip {
  transform: translate 0, 100px;
  z-index: 10;
}

@keyframes tooltip {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

#search {
  width: 62.5%;
  margin: 0 auto;
  padding-top: 1.25em;
}

#search input {
  width: 100%;
  height: 2.5em;
  font-size: 1.25em;
  padding: 0.3125em;
  box-sizing: border-box;
}

#search-results {
  margin-top: 1.25em;
}

#search-results .search-result {
  padding-bottom: 1.25em;
  width: 650px;
}

#search-results .term {
  color: #406afc;
  font-size: 1em;
  padding-bottom: 1.25em;
  padding-bottom: 0;
}

#search-results .term .item_date {
  text-transform: none;
  font-size: 0.8em;
  color: #c1c5c9;
  font-weight: 300;
}

#search-results .subhead {
  text-transform: none;
  font-size: 0.8em;
  color: #6A727C;
  font-weight: 400;
  padding-bottom: 0.3125em;
}

#search-results .description {
  font-size: 0.8em;
  color: #000000;
}
